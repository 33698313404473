import PropTypes from 'prop-types';

function MainLayout({ children }) {
  return <div id="main__container">{children}</div>;
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default MainLayout;
